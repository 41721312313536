/*
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.wrapper .passwordStrengthContent {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.wrapper .passwordStrengthContent .passwordStrengthLabel {
  font-size: 14px;
  line-height: 20px;
  margin-right: 0.5rem;
}
.wrapper .help {
  fill: #0066b4;
  margin-left: 10px;
  vertical-align: middle;
}
.wrapper .levels {
  padding-top: 0.75rem;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}
.wrapper .levels span {
  display: inline-block;
  height: 4px;
  border-radius: 2px;
  background-color: #dedede;
  flex-grow: 1;
  margin: 0 2px;
}
.wrapper .levels .reached {
  background-color: #73b421;
}
.wrapper.red .reached {
  background-color: #dc4306;
}
.wrapper.red .messageIcon {
  fill: #dc4306;
}
.wrapper.orange .reached {
  background-color: #f5b407;
}
.wrapper.orange .messageIcon {
  fill: #f5b407;
}
.wrapper .messageContainer {
  padding-top: 0.5rem;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.wrapper .messageContainer .errorIcon {
  width: 14px;
  height: 14px;
}
.wrapper .messageContainer .errorMessage {
  margin-left: 0.5rem;
}