/**
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.consent-header {
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 550;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 1rem;
}

.consent-subheader {
  color: #3c3c3c;
  font-weight: 550;
  margin-top: 1rem;
}

.consent-before-block {
  margin-bottom: 1rem;
  height: 2.5rem;
  color: #3c3c3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.consent-body {
  padding: 2rem;
  text-align: left;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.consent-footer {
  width: 21rem;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  color: #3c3c3c;
  padding-top: 0.375rem;
  margin-bottom: 1.25rem;
}

.btn {
  width: 45%;
  text-align: center !important;
  height: 2.5rem !important;
  font-weight: bold !important;
  justify-content: center !important;
}

.modal-btn {
  text-align: center !important;
  height: 2.5rem !important;
  margin-left: 0 !important;
  width: 6rem !important;
}

.il-btn-container {
  display: flex;
  justify-content: space-between;
}

.scope-list {
  padding-bottom: 1rem;
}

.scope-list-item {
  display: flex;
  justify-content: space-between;
  overflow: auto;
}
.scope-list-item label {
  margin-right: 0.5rem;
  font-size: 14px;
  font-weight: 600;
  color: #3c3c3c;
}

.scope-separator {
  width: 100%;
  height: 2px;
  background: #D9D9D9;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

@media screen and (max-width: 600px) {
  .consent-body {
    width: 80vw !important;
  }
}
.profileScopeListItem {
  display: flex;
}

.icon-warning {
  margin-left: 0.1875rem;
  margin-right: 0.5rem;
  fill: #db3535;
}

.icon-holder span svg {
  height: 2rem;
}

.addPaddingTopBottom {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}