/**
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.profileAppContainer {
  padding: 2rem;
}

.profileDropDown {
  display: none;
}

@media only screen and (max-width: 672px) {
  .profileAppContainer {
    padding: 0;
  }

  .profileDropDown {
    padding: 1rem;
    display: block;
  }
}