/**
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.eula-header {
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.eula-before-block {
  padding-top: 8px;
  height: 40px;
  color: #3c3c3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.eula-body {
  height: 356px;
  padding: 32px;
  text-align: left;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.eula-body.eula-body-collapsed {
  height: 75px;
}

.eula-agreement-body {
  padding-left: 16px;
  margin-top: 9.5px;
  margin-right: 19px;
  overflow-y: scroll;
  height: 108px;
  color: #3c3c3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  scrollbar-base-color: #0066b4;
  scrollbar-color: #0066b4;
}
.eula-agreement-body div {
  padding-right: 9px;
}

.eula-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.collapseButton {
  min-height: auto;
  position: relative;
  height: 16px !important;
  background-color: transparent;
}
button.collapseButton:hover {
  background: #ffffff !important;
}

.collapseIcon {
  fill: #3c3c3c;
  width: 12px;
  height: 12px;
}

.eula-agreement-wrapper {
  box-sizing: border-box;
  height: 128px;
  margin-top: 31px;
  border: 1px solid #dedede;
  border-radius: 4px;
  background-color: #ffffff;
}

.eula-footer {
  width: 286px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  color: #3c3c3c;
  padding-top: 6px;
  margin-bottom: 20px;
}

.btn {
  width: 136px;
  text-align: center !important;
  height: 40px !important;
  font-weight: bold !important;
  padding-left: 40px !important;
}

.modal-btn {
  text-align: center !important;
  height: 40px !important;
  margin-left: 0 !important;
  width: 6rem !important;
}

.acceptBtn {
  float: right;
}

.declineBtn {
  float: left;
}

.il-btn-container {
  text-align: center;
  padding-top: 32px;
}

.eula-agreement-body::-webkit-scrollbar {
  width: 0.3rem;
}

.eula-agreement-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.eula-agreement-body::-webkit-scrollbar-thumb {
  background-color: #007aff;
  outline: 1px solid slategrey;
}

.eula-confirm-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.il-eula-content-tiles {
  color: #3c3c3c;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 800;
  padding-top: 17px;
}

.il-eula-content-sub-content {
  color: #3c3c3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 17px;
}

.profileSetupContainer {
  margin-top: 1rem;
  max-width: 356px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  text-align: left;
  border-radius: 4px;
  background-color: #ffffff;
  color: #3c3c3c;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.profileSetupContainer .emailContainer {
  padding: 0.75rem 0;
}
.profileSetupContainer select[class=bx--select-input] {
  width: 100% !important;
}

@media screen and (max-width: 300px) {
  .btn {
    width: 120px;
  }

  .eula-body {
    width: 288px;
  }
}
@media screen and (max-width: 400px) {
  .eula-body {
    width: 288px;
    padding: 24px;
  }

  .btn {
    width: 113px;
  }
}
@media screen and (max-width: 289px) {
  .btn {
    width: 120px;
  }

  .eula-body {
    width: 100%;
  }
}