/**
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
.wrapper, .innerwrapper {
  background-color: #f6f6f6;
}

.innerwrapper {
  text-align: -webkit-center;
}

@-moz-document url-prefix() {
  .innerwrapper {
    text-align: -moz-center;
  }
}
.container {
  margin: 0 auto;
  max-width: 350px;
  background-color: #f6f6f6;
  color: #a3a3a3;
  padding-top: 56px;
}
.container .logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}
.container .footer {
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  padding: 24px 0;
}
.container .copyright {
  color: #a3a3a3;
}
.container .legal a {
  display: inline-block;
  margin: 0 5px;
  color: #a3a3a3;
}