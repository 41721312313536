/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.title {
  padding-bottom: 0;
  padding-top: 0;
}

.factorsList {
  list-style-type: none;
  padding: 1rem 0 0 0;
  color: #3c3c3c;
}
.factorsList li {
  padding: 1rem 0;
  border-top: 1px solid #DEDEDE;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.factorsList li button {
  min-width: 75px;
}

.enrolledStatus {
  display: flex;
  justify-content: center;
  align-items: center;
}
.enrolledStatus img {
  margin-right: 1rem;
  width: 16px;
  height: 16px;
}

.factorTitle {
  font-weight: 600;
}

.securityLevel img {
  fill: #3c3c3c;
  vertical-align: bottom;
  padding: 0 2px;
}

.smsActions {
  display: none;
}

.resendLink {
  cursor: pointer;
}

.countryCode {
  width: 100%;
}

.backLink {
  color: #3c3c3c;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  margin-bottom: 2rem;
}
.backLink:hover {
  text-decoration: none;
}
.backLink svg {
  vertical-align: text-top;
  margin-right: 5px;
}
.backLink svg path {
  fill: #999999;
}

.helpText {
  padding-bottom: 16px;
}

.enrollSmsInputContainer {
  width: 320px;
}

.enrollSmsSelectContainer {
  width: 320px;
  margin-bottom: 1rem;
}

.enrollSmsNoCodeLabel {
  margin-top: 1rem;
}

div[class=bx--select-input__wrapper] {
  width: 100%;
}

.enrollSmsCodeActiveContainer {
  display: flex;
}

.enrollSmsCodeActive {
  width: 320px;
  margin-right: 1rem;
  display: inline-block;
}

.enrollSmsConfirmBtn {
  display: inline-block;
  vertical-align: bottom;
  margin-top: 2.1rem;
}

.verififcationField {
  display: flex;
}

.factorTitleContainer {
  width: 100%;
  display: flex;
}
.factorTitleContainer .factorTitle {
  width: 45%;
}
.factorTitleContainer .securityLevel {
  width: 55%;
}

@media (max-width: 672px) {
  .enrollSmsInputContainer {
    width: 100%;
  }

  .enrollSmsSelectContainer {
    width: 100%;
  }

  .smsActions {
    margin-top: 2rem;
    display: flex;
  }

  .verififcationField {
    display: grid;
    flex-direction: row;
  }

  .enrollSmsConfirmBtn {
    margin-top: 2rem;
  }

  .factorTitleContainer {
    flex-direction: column;
  }
  .factorTitleContainer .factorTitle {
    width: 100%;
    min-height: 20px;
    margin-bottom: 0.25rem;
  }
  .factorTitleContainer .securityLevel {
    width: 100%;
    min-height: 20px;
  }
}