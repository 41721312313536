/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.updateCard {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 32px 32px;
}

.inputFields_Wrapper {
  width: 30%;
}

.currentPasswordContainer {
  margin-top: 2rem;
}

.updatePasswordContainer {
  margin-top: 2rem;
}

.formTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.repeatPassword {
  margin-top: 1rem;
}
.repeatPassword svg[class=bx--text-input__invalid-icon] {
  right: 52px;
}

.profileNewPasswordField svg[class=bx--text-input__invalid-icon] {
  right: 52px;
}

.indicatorWrapper {
  margin-top: 0.5rem;
}

@media screen and (min-width: 672px) and (max-width: 1056px) {
  .inputFields_Wrapper {
    width: 100%;
  }
}
@media (max-width: 672px) {
  .updateCard {
    box-shadow: none;
    background-color: #ffffff;
    padding: 1rem;
    margin-top: 0;
  }

  .currentPasswordContainer {
    margin-top: 0.5rem;
  }

  .inputFields_Wrapper {
    width: 100%;
  }

  .visibilityIconContainer {
    right: 1.5rem;
  }
  .visibilityIconContainer .visibileIcon {
    left: 0;
  }
}