/**
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.updateCard {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem 2rem;
}
.updateCard :global(.bx--data-table-header) {
  padding: 0 0 1.5rem 1rem;
  margin-bottom: 1rem;
}
.updateCard :global(.bx--data-table-header__title) {
  font-weight: 600 !important;
}
.updateCard :global(.il-framework-component--data-table-group .bx--data-table-container .bx--table-toolbar) {
  display: none;
}
.updateCard :global(.il-framework-component__IlModalV2 .bx--modal-container .bx--modal-header .bx--modal-close) {
  top: 1rem;
  right: 1.5rem;
}

@media (max-width: 672px) {
  .updateCard {
    box-shadow: none;
    background-color: #ffffff;
    padding: 1rem;
    margin-top: 0;
  }
}