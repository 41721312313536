/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.chartsErrorComponent {
  background-color: #ffffff;
  height: auto;
  padding: 6rem;
  width: auto;
  border-radius: 0.125rem;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  margin: 2rem;
}
.chartsErrorComponent div[class=il-states-content] {
  padding-right: 8rem;
}

.chartDescription {
  margin-top: 1rem;
}