/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.container {
  padding: 0;
}

.ilForm {
  border-radius: 4px;
  background-color: #ffffff;
  text-align: left;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 32px 32px;
}

.formTitle {
  color: #3c3c3c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.link {
  text-decoration: none;
}

.il-input-container {
  position: relative;
}
.il-input-container .il-email-warning {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.il-input-container .input-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
}
.il-input-container .visibility-toggle {
  position: absolute;
  right: 1px;
  top: 35px;
  height: 37px;
  min-height: 0;
}
.il-input-container .visibility-icon {
  width: 16px;
  height: 16px;
}
.il-input-container .il-email-normal {
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #dedede;
}
.il-input-container .il-email-error {
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #db3535;
}

.toastContainer {
  margin-bottom: 15px;
}

.il-error-label {
  color: #db3535;
  padding-top: 5px;
}

.il-email-error:active {
  outline: none !important;
}

.il-email-error:focus {
  outline: none;
}

button.actionButton {
  justify-content: center;
  min-height: 40px;
  padding: 0;
}
button.actionButton.secondary {
  background-color: #ffffff;
  border: 1px solid #0066b4;
  color: #0066b4;
}

.indicatorWrapper {
  margin: 10px 0 15px 0;
}

.loadingContainer {
  max-width: 115px;
  margin: 0 auto;
}

.helpText {
  color: #3c3c3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 24px;
}

.smaller {
  font-size: 12px;
}

.noPadding {
  padding: 0;
}

.rightButtonContainer {
  display: flex;
  margin-top: 2rem;
}

.noMargin {
  margin: 0;
}

@media (max-width: 672px) {
  .ilForm {
    box-shadow: none;
    background-color: #ffffff;
    padding: 1rem;
    margin-top: 0;
  }

  .formTitle {
    padding-top: 0;
    padding-bottom: 0;
  }

  .helpText {
    padding-bottom: 0.5rem;
  }
}