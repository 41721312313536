/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
.app {
  text-align: center;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}
.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin: auto;
  width: 50%;
  padding: 10px;
}

.app-link {
  color: #704cb6;
  border: thick;
  border-color: 0;
}

.toastComponentCls {
  width: 600px;
  margin-left: 35%;
  position: absolute;
  z-index: 10;
  margin-top: 10px;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
@media screen and (min-width: 672px) and (max-width: 1056px) {
  .toastComponentCls {
    margin-left: 20%;
  }
}
@media (max-width: 672px) {
  .toastComponentCls {
    width: 100%;
    margin-left: 0;
  }
}