/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.smallSize {
  width: 16px;
  height: 16px;
}

.extraSmallSize {
  width: 14px;
  height: 14px;
}

.mediumSize {
  width: 32px;
  height: 32px;
}

.iconHolder {
  display: flex;
}

.iconFillPrimaryColor {
  fill: #0066b4;
}

.iconFillRedColor {
  fill: #e00000;
}

.iconFillYellowColor {
  fill: #f5b407;
}

.iconFillGreenColor {
  fill: #69a204;
}

.backArrowColor {
  fill: #999999;
}