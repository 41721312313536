.icon {
  cursor: pointer;
  border-radius: 2px;
  padding: 2px;
}
.icon:hover {
  background-color: var(--primaryLighter, #e7f5ff);
}
.icon:focus, .icon:active {
  border: 2px solid var(--primary, #0066b4);
}

.fill {
  fill: var(--primary, #0066b4);
}