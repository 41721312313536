/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.entitledAppsTitle {
  display: inline-block;
  color: #3c3c3c;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0;
  margin: 0 0 1rem 0;
}

.entitledApps {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.entitledApp {
  width: 272px;
  border: 1px solid transparent;
  padding: 2rem;
  letter-spacing: 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  color: #3c3c3c;
  text-decoration: none !important;
  position: relative;
}
.entitledApp:hover {
  background-color: var(--brandprimarylighthover);
  border: 1px solid #0077be;
}
.entitledApp :hover {
  color: #3c3c3c;
}
.entitledApp:active {
  background-color: var(--brandprimarylightpressed);
  border: 1px solid #0077be;
}
.entitledApp .entitledAppTitle {
  display: block;
  font-size: 24px;
  margin-bottom: 1rem;
}
.entitledApp .entitledAppTitle sup {
  font-size: 12px;
}
.entitledApp .entitledAppDescription {
  font-size: 14px;
  line-height: 20px;
}

.entitledAppIcon {
  display: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .entitledApp {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 610px) {
  .entitledApp {
    width: 100%;
  }

  .entitledAppIcon {
    display: block;
    position: absolute;
    top: 50%;
    right: 1rem;
    fill: #999999;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -1rem;
  }
}
.profileInfo {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 4px;
  margin-top: 1rem;
}

.profileTitleCls {
  display: flex;
  border-bottom: 1px solid #dedede;
  align-items: center;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.profileInfoTitleHeader {
  color: #3c3c3c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.profileInfoTitle {
  color: #3c3c3c;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: -0.5rem;
}

.profileInfoCls {
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.profileInfoHeader {
  color: #3c3c3c;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 40px;
}

.profileEmailCls {
  padding-bottom: 0.5rem;
}

.profileInfoTitle2 {
  color: #3c3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  width: 9rem;
}

.profileTimezone {
  color: #3c3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  width: 9rem;
}

.profileCls {
  color: #3c3c3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.profileCls2 {
  color: #3c3c3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.phoneTitle {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  color: #3c3c3c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.iconsStyle .profile_edit_phoneDeleteBtn .profile_edit_phone-delete {
  width: 16px;
  height: 16px;
  fill: #0066b4;
}

.addressTitle {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #3c3c3c;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.profileInfoTitle3 {
  color: #3c3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.profileInfoTitle4 {
  color: #3c3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.profileClsnumber {
  color: #3c3c3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 9rem;
  margin-top: -1.2rem;
  margin-bottom: 1rem;
}

.profileAddressFields {
  color: #3c3c3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 9rem;
  margin-top: -1.2rem;
  margin-bottom: 1rem;
  padding-bottom: 0.4rem;
}

.buttonHide {
  display: none !important;
}

.iconHide {
  display: block;
}

/************************************/
.profileEdit {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.profilePhoneEdit {
  display: flex;
  flex-direction: column;
}

.editProfileBtnMobile {
  display: none;
}

.editProfileButtonsDesktop {
  display: flex;
}
.editProfileButtonsDesktop .buttonCancel {
  margin-right: 1rem;
}

.editScreen {
  width: 656px;
}
.editScreen .emailInput {
  width: 100%;
  margin-top: 1rem;
}
.editScreen .profileNameInputContainer {
  display: flex;
  margin-top: 1rem;
}
.editScreen .profileNameInputContainer .firstInput {
  width: 50%;
  padding-right: 0.5rem;
}
.editScreen .profileNameInputContainer .secondInput {
  width: 50%;
  padding-left: 0.5rem;
}
.editScreen .dropDownTitle {
  display: flex;
  margin-top: 1rem;
}
.editScreen .dropDownTitle .titleSelectField {
  width: 50%;
  padding-right: 0.5rem;
}
.editScreen .dropDownTitle .titleSelectField .selectFields select[class=bx--select-input] {
  width: 100%;
}
.editScreen .dropDownTitle .selectFields2 {
  width: 50%;
  padding-left: 0.5rem;
}
.editScreen .dropDownTitle .selectFields2 .selectFields select[class=bx--select-input] {
  width: 100%;
}
.editScreen .dropDownFields {
  display: flex;
  margin-top: 1rem;
}
.editScreen .dropDownFields .selectFieldLocale {
  width: 50%;
  padding-right: 0.5rem;
}
.editScreen .dropDownFields .selectFieldLocale .selectFields select[class=bx--select-input] {
  width: 100%;
}
.editScreen .dropDownFields .selectFields2 {
  width: 50%;
  padding-left: 0.5rem;
}
.editScreen .dropDownFields .selectFields2 .selectFields select[class=bx--select-input] {
  width: 100%;
}
.editScreen .addPhoneSection {
  display: flex;
  margin-bottom: 1rem;
}
.editScreen .addPhoneSection .phoneTypeField {
  width: 45%;
  padding-right: 0.5rem;
}
.editScreen .addPhoneSection .phoneTypeField select[class=bx--select-input] {
  width: 100%;
}
.editScreen .addPhoneSection .contactField {
  width: 45%;
  padding-left: 0.5rem;
}
.editScreen .addPhoneSection .contactField select[class=bx--select-input] {
  width: 100%;
}
.editScreen .addPhoneSection .iconsStyle {
  width: 10%;
  padding-left: 1rem;
  margin-bottom: 0;
  margin-top: auto;
  height: 100%;
}
.editScreen .addressFiled {
  width: 100%;
}
.editScreen .addressFiled2 {
  width: 100%;
  margin-top: 1rem;
}
.editScreen .addressFields {
  display: flex;
  margin-top: 1rem;
}
.editScreen .addressFields .cityFields {
  width: 50%;
  padding-right: 0.5rem;
}
.editScreen .addressFields .stateFields {
  width: 50%;
  padding-left: 0.5rem;
}
.editScreen .postalAddressSection {
  display: flex;
  margin-top: 1rem;
}
.editScreen .postalAddressSection .postalInput {
  width: 50%;
  padding-right: 0.5rem;
}
.editScreen .postalAddressSection .selectFields3 {
  width: 50%;
  padding-left: 0.5rem;
}
.editScreen .postalAddressSection .selectFields3 .selectFields select[class=bx--select-input] {
  width: 100%;
}

.editProfileButtonsMobile {
  display: none;
}

.btnContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 672px) and (max-width: 1056px) {
  .editScreen {
    width: 100%;
  }

  .editProfileButtonsDesktop {
    display: none;
  }

  .editProfileBtn {
    display: none;
  }

  .editProfileBtnMobile {
    display: block;
    margin-top: 2rem;
  }

  .editProfileButtonsMobile {
    display: flex;
    margin-top: 2rem;
  }
  .editProfileButtonsMobile .buttonCancel {
    padding-right: 0.5rem;
  }
  .editProfileButtonsMobile .buttonCancel button {
    width: 100%;
    justify-content: center;
  }
  .editProfileButtonsMobile .buttonSave {
    padding-left: 0.5rem;
  }
  .editProfileButtonsMobile .buttonSave button {
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 672px) {
  .entitledAppsContainer {
    background: #ffffff;
    padding: 1rem;
  }

  .profile2Info {
    box-shadow: none;
    padding: 2rem 0px;
    margin-top: 0;
  }

  .editProfileBtn {
    display: none;
  }

  .editProfileBtnMobile {
    display: block;
    margin-top: 2rem;
  }

  .editScreen {
    width: 100%;
  }

  .editProfileButtonsMobile {
    display: flex;
    margin-top: 2rem;
  }
  .editProfileButtonsMobile .buttonCancel {
    width: 50%;
    padding-right: 0.5rem;
  }
  .editProfileButtonsMobile .buttonCancel button {
    width: 100%;
    justify-content: center;
  }
  .editProfileButtonsMobile .buttonSave {
    width: 50%;
    padding-left: 0.5rem;
  }
  .editProfileButtonsMobile .buttonSave button {
    width: 100%;
    justify-content: center;
  }

  .editProfileButtonsDesktop {
    display: none;
  }

  .profileInfo {
    box-shadow: none;
    padding: 1rem;
    margin-top: 0;
  }

  .profileTitleCls {
    padding-bottom: 1.5rem;
  }

  .editScreen .profileNameInputContainer {
    margin-top: 0;
    flex-direction: column;
  }
  .editScreen .profileNameInputContainer .firstInput {
    width: 100%;
    padding-right: 0;
    padding-top: 1rem;
  }
  .editScreen .profileNameInputContainer .secondInput {
    width: 100%;
    padding-left: 0;
    padding-top: 1rem;
  }
  .editScreen .dropDownTitle {
    margin-top: 0;
    flex-direction: column;
  }
  .editScreen .dropDownTitle .titleSelectField {
    width: 100%;
    padding-right: 0;
    padding-top: 1rem;
  }
  .editScreen .dropDownTitle .selectFields2 {
    width: 100%;
    padding-left: 0;
    padding-top: 1rem;
  }
  .editScreen .dropDownFields {
    flex-direction: column;
    margin-top: 0;
  }
  .editScreen .dropDownFields .selectFieldLocale {
    width: 100%;
    padding-right: 0;
    padding-top: 1rem;
  }
  .editScreen .dropDownFields .selectFields2 {
    width: 100%;
    padding-left: 0;
    padding-top: 1rem;
  }
  .editScreen .addPhoneSection {
    flex-direction: column;
    margin-bottom: 0;
  }
  .editScreen .addPhoneSection .phoneTypeField {
    width: 100%;
    padding-right: 0;
  }
  .editScreen .addPhoneSection .contactField {
    width: 100%;
    padding-left: 0;
    padding-top: 1rem;
  }
  .editScreen .addPhoneSection .iconsStyle {
    width: 100%;
    padding-left: 0;
    padding-top: 1rem;
    margin-bottom: 2rem;
  }
  .editScreen .addPhoneSection .iconsStyle .iconHide {
    display: none;
  }
  .editScreen .addPhoneSection .iconsStyle .buttonHide {
    display: block !important;
  }
  .editScreen .addressFields {
    flex-direction: column;
    margin-top: 0;
  }
  .editScreen .addressFields .cityFields {
    width: 100%;
    padding-right: 0;
    padding-top: 1rem;
  }
  .editScreen .addressFields .stateFields {
    width: 100%;
    padding-left: 0;
    padding-top: 1rem;
  }
  .editScreen .postalAddressSection {
    flex-direction: column;
    margin-top: 0;
  }
  .editScreen .postalAddressSection .postalInput {
    width: 100%;
    padding-right: 0;
    padding-top: 1rem;
  }
  .editScreen .postalAddressSection .selectFields3 {
    width: 100%;
    padding-left: 0;
    padding-top: 1rem;
  }
}