/**
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.delete-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.expanded-row {
  display: flex;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
}

.innerapp-title {
  min-width: 6rem;
  margin-right: 2rem;
}