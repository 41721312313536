/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text {
  color: #3c3c3c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.leftMargin {
  margin-left: 10px;
}

.copyButton {
  width: 40px;
  padding: 10px !important;
}

.tooltip {
  width: 60px;
}

.divider {
  margin-top: 10px;
  margin-bottom: 20px;
}

.activationContainer {
  margin-top: 2rem;
  display: flex;
}

.backLink {
  color: #3c3c3c;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  margin-bottom: 2rem;
}
.backLink:hover {
  text-decoration: none;
}
.backLink svg {
  vertical-align: text-top;
  margin-right: 5px;
}
.backLink svg path {
  fill: #999;
}

.blockSpan {
  display: inline-block;
}
.blockSpan .copyButton {
  height: 40px;
}

.helpText {
  padding-bottom: 0.5rem;
}

.helpTextOptions {
  display: flex;
  flex-direction: column;
}
.helpTextOptions .authOption {
  padding-bottom: 0.25rem;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.qrContainer {
  width: 100%;
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 0.75rem;
}

.authSecureKeyLabel {
  font-weight: 600;
}

.authInputContainer {
  width: 320px;
}

.badge {
  width: 135px;
  height: 40px;
}

.badgeContainer {
  margin-top: 20px;
}

.break {
  border-bottom: 1px solid #dedede;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
}

@media only screen and (max-width: 672px) {
  .qrContainer {
    justify-content: center;
  }

  .authInputContainer {
    width: 100%;
  }
}