/**
 * Copyright 2022, IntraLinks, Inc. All rights reserved.
 */
/**
 * Copyright 2020, IntraLinks, Inc, All rights reserved.
 */
/**
 * Copyright 2021, IntraLinks, Inc, All rights reserved.
 */
.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.NavLink {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
  text-decoration: none;
}
.NavLink:global(.active) {
  background-color: var(--brandprimarylightpressed);
  text-decoration: none;
}
.NavLink:hover {
  background-color: var(--brandprimarylighthover);
  text-decoration: none;
}