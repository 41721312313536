.error-title {
  font-size: 24px;
  color: #3c3c3c;
  line-height: 29px;
  padding-bottom: 16px;
}

.error-content {
  font-size: 14px;
  color: #3c3c3c;
  line-height: 18px;
  padding-bottom: 32px;
}

.error-support {
  font-size: 12px;
  color: #3c3c3c;
  line-height: 18px;
}

.error-container {
  width: 100%;
  height: 100%;
  background-color: white;
}

.error-text-container {
  padding-top: 10%;
  float: left;
  min-width: 50%;
  height: 29px;
  width: 99px;
  color: #3C3C3C;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 29px;
}

.main-error-container .parent {
  text-align: -webkit-center;
}

.main-error-container {
  background-color: white;
  height: 496px;
  padding: 32px;
  width: 82%;
  border-radius: 2px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  margin: 32px;
  margin-left: 9%;
}

.il-footer {
  margin-left: 9% !important;
  margin-bottom: 23% !important;
}

.error-container .row {
  max-width: 960px;
  margin: 0 auto;
}

.error-image {
  padding: 80px 0 80px 0;
}

.error-teapot-image {
  padding-top: 40px;
}

div a {
  color: #0077be;
}

div a :hover {
  color: #0077be;
}

html > body > #main {
  text-align: center;
  flex-grow: 0;
}

@media screen and (max-width: 764px) {
  .main-error-container {
    display: grid;
    width: 100%;
    height: 75%;
    border: 16px solid #f6f6f6;
    box-shadow: none;
    overflow: hidden;
    padding: 0px;
  }

  .error-text-container {
    width: 100%;
    text-align: center;
  }

  .error-title {
    max-width: 92%;
    text-align: center;
  }

  .error-content {
    text-align: center;
    max-width: 59%;
    margin-left: 20%;
  }

  .error-image {
    margin-top: 212px;
    padding: 0;
    text-align: center;
  }

  .error-img {
    height: 192px;
    width: 226px;
  }

  .error-teapot-image {
    height: 192px;
    width: 226px;
  }

  .error-teapot-img {
    margin-top: 150px;
    text-align: center;
  }
}
@media screen and (max-width: 1366px) {
  .main-error-container {
    display: grid;
    width: 100%;
    height: 75%;
    box-shadow: none;
    border: 32px solid #f6f6f6;
    margin: 0;
  }

  .error-text-container {
    min-width: 100%;
  }

  .error-image {
    margin-top: 80px;
  }
}
@media screen and (max-height: 680px) {
  .main-error-container {
    height: 90%;
  }
}